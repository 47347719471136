.sidebar-menu {
    background: #d9e9ae;
    margin-top: 2.5rem;

     a, li {
       font-size: 1.6rem;
       color: #000;
     }

     a {
       color: #000;
     }

    ul {
      list-style: none;
      padding: 0;
    }

  .uk-accordion-title {
    padding: 1rem 3rem;
  }
  .uk-accordion-content {
    margin: 0;
  }

  ul.mod-list {

      li {
        padding: 0;
        margin: 0;
      }

      > li {
        //margin: 1rem 2rem;

      }

      > li.active {
        > a {
          font-weight: 700;
          color: $primary_color;
        }
      }

    }

    ul.level2 {
      background: #eaf3d2;

       li {
         padding: 0.5rem 1rem 0.5rem 6rem;
       }

       li.active {
         a {
           font-weight: 700;
           color: $primary_color;
        }
      }
    }
}