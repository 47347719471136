.park {

  h2 {
    @include _title;
    margin: 0;

  }

  .item {
    display: flex;
    margin-bottom: 2rem;

      p {
        width: 100%;
        margin: 0;
      }

      strong {
        padding-bottom: 2rem;
        display: block;
      }

      .name {
        width: 33%;
        background: #a8a8a7;
        justify-content: center;
        align-items: center;
        display: flex;
        color: #FFFFFF;
        font-size: 4.5rem;
        font-weight: 700;
        line-height: 6rem;
        padding: 3rem;
        text-align: center;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;         /* Opera/IE 8+ */

      }
      .desc {
        width: 33%;
        order: 2;
        justify-content: left;
        align-items: center;
        display: flex;
        padding: 2rem;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box;    /* Firefox, other Gecko */
        box-sizing: border-box;         /* Opera/IE 8+ */
      }
      .image {
        width: 33%;
        height: 30rem;
        background-repeat: no-repeat;
        background-size: cover;
      }



  }

  .item:nth-child(even) {
    .desc {
      background: #eaf3d2;

    }
    .name {
      order: 1;
    }
    .image {
      order: 3;
    }

  }

  .item:nth-child(odd) {
    .name {
      order: 3;
    }
    .image {
      order: 1;
    }
  }

}