@media only screen and (max-width : 1660px) {

  html {
    font-size: 10px;
  }
  .container-submenu {
    padding: 0 12rem;
  }
}

@media only screen and (max-width : 1366px) {
  .photo1 {
    height: 38rem;
  }

  .container-submenu {
    padding: 0 4rem;
  }

  .submenu2 .text {
    height: 8rem;
  }

  .blog-container .item,
  .realizacje .item
  {
    height: 20rem;

  }

}

@media only screen and (max-width : 1140px) {
  .container-submenu {

    .uk-container .uk-grid-row-large{
      align-items: center;
      justify-content: center;
    }

    .submenu-item {
      width: 30% !important;
    }
  }

  .submenu2 .text {
    height: 14rem;
  }

  .countdown {
    width: 82%;
  }

  .blog-container .item,
  .realizacje .item
  {
    height: 18rem;

  }


}

@media only screen and (max-width : 1024px) {

  .container-submenu {
    padding: 0 1rem;
  }

  .logo {
    width: 20rem;
    height: 6rem;
  }

  .photo1 {
    height: 33rem;
  }

  //featured
  .featured-grid {
    align-items: flex-start !important;
  }

  .container-featured .featured-right img,
  .container-featured .featured-left img {
    width: 42rem;
  }

  .container-featured .featured-left img {
    padding: 4rem 0 0 1rem;
  }

  .container-featured .featured-right img {
    padding-right: 1rem;
  }
  //end featured

  //footer

  footer .footer-info
  {
    padding-left: unset;
  }

  footer .footer-logo-container {
    padding-left: 30px;
  }

  footer .ico, footer img {
    display: none;
  }

  //end footer

  .article-container .article-columns-wrapper,
  .article-container .map-container .map-wrapper
  {
    width: 80%;
  }

  .submenu2 {
    .item {
      width: 30%;
      margin-bottom: 3rem;
    }

    .text {
      height: 9rem;
    }
  }

}

@media only screen and (max-width : 959px) {

  //featured
  .container-featured .featured-right img,
  .container-featured .featured-left img {
    width: 100%;
  }

  .container-featured .featured-left img,
  .container-featured .featured-right img {
    padding: 0;
    margin: 0;
  }

  .featured-right h3 {
    margin-top: 0;
  }
  //end featured

}

@media only screen and (max-width : 860px) {

  .container-submenu {
    padding: 0 0;
    margin: 2rem 0 5rem 0;

    .uk-container {
      padding: 0;
    }

    .submenu-item {
      width: 100% !important;

    }

    .submenu-inner,
    .submenu-outer
    {
      transform: none;
    }

    .submenu-inner {
      left: 0;
      width: 100%;
      background-position: center;
    }

    .submenu-title {
      margin: 2rem 0 .5rem 0;
      font-size: 2.1rem;

    }

    .uk-grid-row-large {
      margin: 0;
    }

    .submenu-item {
      padding: 0;
    }

  }
}


@media only screen and (max-width : 768px) {



  h1 {
    font-size: 3rem;
  }

  .photo1 {
    height: 27rem;
  }

  .article-container .article-columns-wrapper,
  .article-container .map-container .map-wrapper
  {
    width: 100%;
  }


  footer .text {

    padding-left: 2rem;
    margin-top: 0.4rem !important;
  }

  footer .footer-info .uk-first-column {
    padding-left: 30px;
  }

  footer .first-text {
    padding-left: 0;
  }


}




@media only screen and (max-width : 690px) {

  .search-box {
    display: none;
  }
  
}



@media only screen and (max-width : 420px) {

  body {
    font-size: 1.8rem;
    line-height: 2.6rem;
  }



  h1, h3 {
    font-size: 3.4rem;
    line-height: 3.7rem;
    font-weight: 700;
  }

  .photo1 {
    height: 20rem;
  }

  .intro-content,
  .container-featured p
  {
    text-align: left;
  }

  footer .footer-info .uk-first-column {
    padding-left: 45px;
  }

  //

  .submenu2 .text {
    height: 9rem;
  }

  .textbox--grey .item {
    display: block;
  }

  .textbox--grey .item .text {
    width: 100%;
  }

  .textbox--grey .item .image {
    width: 100%;
  }

  .textbox--grey .item .text .content h2, .textbox--grey .item .text .content ul {
    margin: 0;
  }

  .textbox--grey {
    padding-bottom: 3rem;
  }

  .park .item {
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .park .item .name {
    width: 100%;
    order: 1 !important;
  }
  .park .item .desc {
    width: 100%;
    order: 2 !important;
  }
  .park .item .image {
    width: 100%;
    order: 3 !important;
  }

  .countdown {
    margin-top: 6rem;
  }

  .countdown .label {
    line-height: 4rem;
  }

  .submenu2 .item {
    width: 100%;
  }

  .realizacje .title {
    margin: 0;
    padding: 2rem 0 2rem 0;
    line-height: 4rem;
  }

  .article-container .column-block h2 {
    margin: 1rem 0 2.5rem 0
  }

  .article-container .column-block {
    margin-bottom: 6rem;
  }

  .form-container .form-row-1 .form-group {
    width: 96%;
    margin-bottom: 2rem;
  }

  .form-container .form-row-1 {
    flex-direction: column;
  }

  .form-container .form-row-2 textarea {
    width: 96%;
  }

  .form-container .button input {
    width: 96%;
    padding: 1rem 2rem;
  }

}




@media (max-width: 710px) and (orientation: landscape) {

}

