.textbox {
  h2 {


    @include _title;
    margin: 0;

  }
}

.textbox--grey {
  background: #F2F2F2;
  .item {
    display: flex;
    .text {
      width: 65%;
      display: flex;
      justify-content: left;
      align-items: center;
      .content {
        width: 100%;
        h2, ul {
          width: 100%;

        }
      }
    }
    .image {
      width: 35%;
    }
  }

  h2 {
    text-align: left;
  }
  ul {
    padding-left: 0;
    //list-style-image: url('../images/ico-menu.jpg');

      li {
        margin: 0 0 1rem 0;
        padding: 2px 0 2px 24px;
        list-style: none;
        background-image: url("../images/ico-menu.jpg");
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 10px;


      }

  }
}