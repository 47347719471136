.article-container {

  ///margin-top: 6rem;
  min-height: 100vh - 35vh;

  .article-title {
    @include _title;
    margin: 0;
    border-bottom: 2px solid #000;
  }

  .article-text {
    text-align: justify;
    margin-top: 7rem;

  }

  .article-image {
    img {
      //width: 50rem;
    }
  }

  .column-block {
    text-align: center;
    color: $primary_color;

    h2 {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 3.5rem;
      margin: 2rem 0 5rem 0;
    }

    p {
      margin: 0.2rem 0;
    }

    .icon {
      svg {
        font-size: 2.8rem;
        color: #000;
      }
    }
  }

  .article-columns {
    display: flex;
    justify-content: center;
  }

  .article-columns-wrapper {
    width: 56%;
  }

  .map-container {

    display: flex;
    justify-content: center;
    width: 100%;
      .map-wrapper {
        display: flex;

        width: 56%;
      }

  }

}


body.view-article {
  h1.article-title, #onas h2{
    padding-top: 2rem;
  }
}