.form-container {
  background-color: #F2F2F2;
  margin-top: 10rem;
  padding-bottom: 6rem;

  h2.article-title {
    border-bottom: 2px solid #7F7F7F;
    color: #7F7F7F;
  }

  form {
    margin-top: 4rem;
  }

  label {
    display: none;
  }

  .form-row-1 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    .form-group {
      width: 30%;
      input {
        background-color: #FFFFFF !important;
        box-shadow: none;
        border: 1px solid #7F7F7F;
        width: 100%;
        padding: 0.8rem 0.5rem;
        font-size: 1.6rem;

      }

    }
  }

  .form-row-2 {
    margin-top: 3rem;

    textarea {
      background-color: #FFFFFF !important;
      box-shadow: none;
      border: 1px solid #7F7F7F;
      width: 100%;
      padding: 0.8rem 0.5rem;
      font-size: 1.6rem;

    }
  }

  .button {
    text-align: center;
    input {
      text-transform: uppercase;
      background: #3c3;
      padding: 1rem 10rem;
      color: #FFFFFF;
      font-weight: 600;
      border: 0;
      font-size: 1.6rem;
      line-height: 2.2rem;
      margin-top: 2rem;
    }
  }

}