section.top {
  position: relative;

  .uk-dotnav {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 1rem;
  }

  .uk-dotnav>.uk-active>* {
    background-color: rgba(116,180,49,.9);
    border-color: transparent;
  }

  .uk-dotnav>*>* {
    width: 20px;
    height: 20px;
    background-color: rgba(116,180,49,.3);
    border: 1px solid rgba(116,180,49,0.4);
  }
}