.container-breadcrumb {
  font-size: 1.4rem;
  margin-top: 4rem;

  .uk-breadcrumb>*>* {
    font-size: 1.4rem;
    color: #000;
  }

  .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column)::before {
    font-size: 1.4rem;
  }

  li.active {
    span {
      color: $primary_color;
      font-weight: 500;
    }
  }

  .uk-breadcrumb>:nth-child(n+2):not(.uk-first-column)::before {
    content: "/";
    display: inline-block;
    margin: 0 5px 0 calc(5px - 4px);
    font-size: 1.4rem;
    color: #000;
  }

  .divider {
    img {
      display: none;
    }
  }

}