// template
//@import "variables";
// uikit
@import "../node_modules/uikit/dist/css/uikit.min.css";


//@import "../node_modules/aos/dist/aos.css";
@import "../node_modules/jquery-simple-mobilemenu/dist/styles/jquery-simple-mobilemenu.css";


// template
@import "custom";

.uk-navbar-dropdown {
  //display: block;
}
