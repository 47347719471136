.search-box {

  width: 13rem;
  float: left;
  display: flex;
  height: 100%;
  align-items: center;

  .uk-search-default .uk-search-input {
    //border: none;
    //border-bottom: 1px solid $primary_color;
    height: auto;
    width: 85%;
    border: 1px solid #dedede;
  }

  .uk-search-input::placeholder {
    color: #cecece;
    font-size: 1.3rem;
  }

  .uk-search .uk-search-icon {
    color: $primary_color;
  }

  button {
    position: absolute;
    right: 0;
    background-color: transparent;
    border: 0;
    line-height: 3rem;
    svg {
      color: #7F7F7F;
    }
  }


}

body.com_search {
  .container-breadcrumb {
    margin-bottom: 2rem;
  }
  .btn-toolbar {
    margin-bottom: 2rem;
  }

  .btn {
    border: none;
    border-bottom: 1px solid $primary_color;
    height: auto;
    background: #FFFFFF;
    margin-top: 3rem;
    font-size: 1.6rem;
    padding: 0.5rem 2rem 0.5rem 0.2rem;
  }
}