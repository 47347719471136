.blog-container {
  h1 {
    @include _title;
    margin: 0;
    margin-bottom: 6rem !important;

    border-bottom: 2px solid #000;
  }

  .item {
    height: 30rem;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid #dedede;
    position: relative;
    background-position: top center;
    .name {
      position: absolute;
      top: 1.2rem;
      left: 1.2rem;
      color: #000;
      font-size: 1.6rem;
      //background-color:rgba(206,206,206,0.6);
      padding: 0.5rem 1rem;
      font-weight: 500;
    }
  }
}