footer {
  background-color: #F2F2F2;
  padding: 1rem 0 1rem 0;
  font-size: 1.4rem;
  font-weight: 500;
  display: none;

  .line {
    background-color: $primary_color_alt;
    height: 4rem;
  }

  .footer-info {
    margin: 1.4rem 0 0 0;
    padding-left: 16rem;
  }

  .footer-logo {
    height: 4rem;
    width: 17em;
    background-image: url("../images/scanwir-footer.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .footer-logo-container {
    padding-left: 12rem;
  }

  img {
    width: 1.3rem;
    background: #3A6F9A;
    vertical-align: middle;
    max-height: 15px;
    max-width: 15px;
  }

  .ico, .start {
    display: flex;
    justify-content: center;
  }

  .ico {
    padding-left: 1rem;
  }
  .text {
    padding-left: 0.4rem;
  }


}