@mixin sub-label {
  color: $primary_color;
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 500;
}

@mixin _title {
  color: $primary_color;
  color: #7F7F7F;
  color: #000;
  font-size: 3.5rem;
  text-transform: uppercase;
  text-align: center;
  padding: 6rem 0 6rem 0;
  margin: 5rem 0 0 0;
}

