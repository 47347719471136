h1 {
  font-size: 2.7rem;
  font-weight: 600;
  text-align: left;
  span {
    color: #FFFFFF;
    font-weight: 700;
  }
}

h3 {
  font-size: 2.7rem;
  font-weight: 500;

  span {
    color: $primary_color;
    font-weight: 700;
  }

}