.submenu2 {
  background-color: #F2F2F2;
  padding: 4rem 0;
  .text {
    font-weight: 500;
    height: 6rem;
    padding: 2.5rem 0 4rem 0;
    font-size: 1.5rem;
  }

  .image {
    img {
      height: 18rem;
    }
  }

  .button-link {
    text-decoration: none;
    color: #FFFFFF;
    font-weight: 500;
    .button {
      text-transform: uppercase;
      background: $primary_color;
      padding: 1rem 0;
    }

  }
}