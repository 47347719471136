#sm_menu_ham {
  background-color: transparent;
}

@media (max-width: 1120px) {

  .uk-navbar-nav {

    height: 60px;

    li a{
      //background: red;
      display: none;
    }

  }


  #sm_menu_ham {
    top: 20px;
  }


  body.home-page {
    #sm_menu_ham {
      top: 20px;
    }
  }



  #sm_menu_ham span {
    background-color: #000;
  }

  #sm_menu_ham.open {
    right: 15px;
  }

  #sm_menu_ham,
  .sm_menu_outer {
    display: block;
    ul {
      padding-left: 0 !important;
    }
  }

  .sm_menu_outer {
    //top: 0;
    height: 100vh;
  }

  .mobile_menu {
    margin-left: 0px;
    margin-top: 100px;

  }

  .sm_menu_outer,
  .sm_menu_outer.slide .mobile_menu .submenu
  {
    background-color: #F2F2F2;
  }


  .sm_menu_outer .mobile_menu li {
    width: 100%;
  }

  .sm_menu_outer .mobile_menu li.back a {

    padding: 15px 5px 15px 75px;
    color: $primary_color;
    font-weight: 500;
    background-position: left;
    margin-left: 10px;

  }



  .sm_menu_outer .mobile_menu a {
    font: 300 18px/140%;
    text-transform: none;
    color: #000;
    font-family: unset;
    padding: 12px 10px;
    font-size: 18px;
    font-weight: 600;
  }

  .sm_menu_outer.slide .mobile_menu .submenu {
    width: 100%;
  }

  .submenu a {
    //background-color: #cecece;
  }

}