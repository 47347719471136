.countdown {
  margin-top: 12rem;
  margin-bottom: 6rem;
  width: 56%;
  .label {
    font-size: 2.7rem;
    font-weight: 600;
    margin-bottom: 3rem;

  }
  .box-wrapper {
    margin: 3rem 0 5rem 0;
    .box {
      //background-image: url("../images/szescian.png");
      background-image: url("../images/wielobok.png");
      background-position: center;
      background-repeat: no-repeat;
      height: 20rem;
      background-size: contain;
      margin: 1rem 0 1.5rem 0;
      position: relative;
      .number {
        color: #000;
        font-size: 4.5rem;
        font-weight: 500;
        position: absolute;
        top: 43%;
        width: 100%;
        text-align: center;
      }
    }
  }

  .text {
    margin-top: 4rem;
    font-size: 2.0rem;
    font-weight: 500;
    text-align: center;
    line-height: 2.6rem;
  }
}