html {
  font-size: 10px;
  height: 100%;
}

body {
  font-size: 1.6rem;
  line-height: 2.2rem;
  //background-color: #ededed;
  background-color: #ffffff;
  color: #000;
  font-family: 'Poppins';
}

.uk-container {
  max-width: 1560px;
}

.photo1 {
  background-image: url("../images/scanwir_baner_01.jpg");
  height: 50rem;
  background-position: center;
}

a {
  color: $primary_color;
}