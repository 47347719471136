.menu-container {
  background-color: #FFFFFF;
  //z-index: 1;
  //position: absolute;
  //top: 2.5rem;
  width: 100%;
}

body.child-page {
  section.top {
    border-bottom: 3px solid $primary_color_alt;
  }
  .menu-container {
    position: static;
  }
}

//kontakt
body.itemid-134 {
  section.top {
    border-bottom: none;
  }
}
//end kontakt
.logo {
  width: 25rem;
  height: 100%;
  background-image: url("../images/scanwir-logo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.uk-navbar-nav li a
{
  font-size: 1.5rem;
  text-transform: none;
  color: #000;
  font-weight: 500;
  text-decoration: none;
  &:hover {
    color: $primary_color;
  }
}

.uk-navbar-nav>li>a:before,
.uk-navbar-nav>li>span:before {
  background-image: url("../images/ico-menu.jpg");
  width: 2rem;
  height: 1rem;
  content: "";
  background-size: contain;
  padding-right: 0.5rem;
  background-repeat: no-repeat;

}

.uk-navbar-nav>li.current>a,
.uk-navbar-nav>li.active>a,
.uk-navbar-nav>li.active>span
{
  color:$primary_color;
}

.uk-navbar-item, .uk-navbar-nav>li>a, .uk-navbar-nav>li>span, .uk-navbar-toggle {
  min-height: 70px;
}

.nav-child {
  list-style: none;
  padding: 0;
  margin-bottom: 4px;

  .active > a {
    color: $primary_color;
  }

  .separator {
    margin-bottom: 0.5rem;
    display: block;
  }

  li {
    padding: 0 10px 3px 10px;
  }
}

.uk-navbar-dropdown {
  width: fit-content;
  padding: 10px 4px 0 10px;
  background-color: rgba(255, 255, 255, .95);
  top: 55px !important;
}

.uk-navbar-dropdown .deeper .nav-child li {
  margin-left: 2rem;
}