.realizacje {

  background: #F2F2F2;
  padding: 1rem 0 6rem 0;
  //padding-bottom: 6rem;
  .title {
    border-bottom: 2px solid #7F7F7F;
    @include _title;
    color: #7F7F7F;
  }

  .item {
    height: 30rem;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid #dedede;
    position: relative;
    background-position: top center;
    .name {
      position: absolute;
      top: 1.2rem;
      left: 1.2rem;
      color: #000;
      font-size: 1.6rem;
      //background-color:rgba(206,206,206,0.6);
      padding: 0.5rem 1rem;
      font-weight: 500;
    }
  }
}